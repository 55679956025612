import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import BottomContent from '../components/bottomContent';
import ContactForm from '../components/contactForm';
import '../styles/contact.css';

const Contact = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={ '6LdLkgoqAAAAALMKH3OHFJFIw__IkVa7hbknEgOx' }>
      <div className="ContactPage">
        <div className="showuppls">
          <h1 className="ContactHeader">CONTACT MASON AYRES CREATIVE</h1>
          <div className="ContactDesc">
            <p>Reach out with your questions/inquiries about graphic design, photography & videography here!</p>
          </div>
        </div>
        <div className="formContainer">
          <ContactForm />
        </div>
        <BottomContent />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default Contact;
