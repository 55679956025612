import '../styles/graphicDesign.css';
import BottomContent from '../components/bottomContent';
import Carousel from '../components/carousel';
import arrowUp from '../resources/Triangle.png';
import arrowDown from '../resources/Arrow Down.png';
import whiteCircle from '../resources/whiteCircle.png';
import whiteSquare from '../resources/whiteSquare.png';
import mario from '../resources/Mario.jpg';

function GraphicDesign() {
    return (
        <div className="graphicDesign">
            <div className='topText'>
                <div className='artDesign'>
                    <h1 className="ArtHeader">ART/DESIGN</h1>
                </div>
                <div className='passion'>
                    <h2 className="PassionHeader">GRAPHIC DESIGN IS MY PASSION.</h2>
                </div>
            </div>
            <div className="graphicDesignBoxes">
            <div className='imgBoxes'>
                <div className='box1'><p>BRANDING</p></div>
                <div className='box2'><p>WEB DESIGN</p></div>
                <div className='box3'><p>ANIMATION</p></div>
            </div>
            <div className='imgBoxes'>
                <div className='box4'><p>PAGE LAYOUT/PUBLICATION</p></div>
                <div className='box5'><p>PRINT SERVICES</p></div>
                <div className='box6'><p>SHIRTS/MERCHANDISE</p></div>
                <div className='box7'><p>ILLUSTRATION</p></div>
            </div>
            <div className='imgBoxes'>
                <div className='box8'><p>AND ANYTHING ELSE ART RELATED</p></div>
                <div className='box9'><p><strong>The term "graphic design" describes a whole myriad of different mediums, ideas, and creative solutions.</strong> Chances are, I can help you out with any creative endeavor that you need assistance with.</p><br></br><p>Except for interpretive dance.</p></div>
            </div>
            </div>
     
        {/*  <div className='servicesText'>
                <div className='services'>
                    <span>SERVICES</span>
                </div>
                <div className='wicdfy'>
                    <span>WHAT I CAN DO FOR YOU</span>
                </div>
            </div> */}
            <div className='offerings'>
                <div>
                    <img src={ arrowUp } alt='arrowUp'></img>
                    <p>BRAND DESIGN</p>
                </div>
                <div className='designBox2'>
                    <img src={ whiteCircle } alt='whiteCircle'></img>
                    <p>WEB DESIGN</p>
                    <img src={ arrowDown } alt='arrowDown'></img>
                </div>
                <div>
                    <img src={ whiteSquare } alt='whiteSquare'></img>
                    <p>PAGE LAYOUT</p>
                </div>
            </div>
            <div className='webDesign'>
                <h2>WHAT DO YOU NEED?</h2>
                <div className='webDesignText'>
                    <p>With over 7 years of experience helping businesses and individuals achieve their creative vision, my team and I take a "needs first" approach.  </p>
                </div>
            </div>
            <div className='resume help'>
                    <p>PEOPLE I'VE HELPED</p>
                </div>
            <Carousel />
            <div className='marioOuterDiv'>
                <div className='marioInnerDiv'>
                    <img src={ mario } alt='mario'></img>
                </div>
                <span>ILLUSTRATION, TOO.</span>
            </div>
            <BottomContent />
        </div>
    )
  };
  
  export default GraphicDesign;
  