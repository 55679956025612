import './App.css';
import Layout from './components/layout';
import Home from './pages/home';
import GraphicDesign from './pages/graphicDesign';
import Photography from './pages/photography';
import Videography from './pages/videography';
import PrivacyPolicy from './pages/privacypolicy';
import About from './pages/about';
import Contact from './pages/contact';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="graphic-design" element={<GraphicDesign />} />
          <Route path="photography" element={<Photography />} />
          <Route path="videography" element={<Videography />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;