import '../styles/home.css'
import LoadingScreen from '../components/loadingscreen';
import brandwork from '../resources/images/brandwork_edited.jpg'
import BottomContent from '../components/bottomContent';
import Carousel from '../components/carousel';
import React, { useEffect, useRef } from 'react';
import camera from '../resources/images/Camera Vector-01.svg';
import { Link } from 'react-router-dom';
function Home() {
  const heroRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!heroRef.current) return;

      const heroHeight = heroRef.current.offsetHeight;

      if (window.scrollY >= heroHeight) {
        heroRef.current.style.position = 'absolute';
        heroRef.current.style.transform = 'translateY(100vh)';
      } else {
        heroRef.current.style.position = 'fixed';
        heroRef.current.style.transform = 'translateY(0vh)';
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="absoluteContainer">
      <LoadingScreen/>
      <div className="HeroContainer">
        <div className='backgroundImage' /*45% opacity*/ >
          <span>I M. A CREATIVE.</span>
        </div>
      </div>
      <div className='PortfolioSlideShow'>
        <div>
        <video src="/videos/BACKGROUND_VIDEO_C.mp4" autoPlay loop muted />
          <span><strong>MASON AYRES CREATIVE:</strong> YOUR CREATIVE PARTNER IN GRAPHIC DESIGN, PHOTOGRAPHY & VIDEOGRAPHY.</span>
        </div>
      </div>
      <div className='content'>

        <div className='identitySection'>
          <div className="brandTitle">
            <h2><span className='brand'>YOUR BRAND</span></h2>
            <h2><span className='identity'>YOUR IDENTITY, IN A NUTSHELL.</span></h2>
          </div>
          <img src={brandwork} alt="brandwork.jpg"></img>
          <div className='descriptionSection'>
            <p className="description">Whether it's the brand of a large business, a solo entrepreneur's startup, or a personal brand, I can help you with laying out the bigger picture. <strong>(Or the small details.)</strong></p> 
            <p className="descriptionSmall">It could be brand visuals, a logo, full brand expressions, or just a consultation to see where your brand can be improved. I want to help you amplify your visibility, earn recognition, and become the go-to choice.</p>
          </div>
        </div>
        <Carousel></Carousel>

        <div className='exposition'>
          <p><strong>WHY GO WITH US</strong></p> <p>If you want a creative partner who isn't afraid to break molds and try something new for your brand or business, you've found a pretty solid option. </p> <p>Plus, we might bring cookies.</p>
        </div>
        <div className='videographySection'>
          <div className='videographyHeader'>
            <span>VIDEOGRAPY & PHOTOGRAPHY</span>
          </div>
        </div>
        <div class='featured'><iframe src='https://www.youtube.com/embed/RQUw1GI__0E' title='Youtube Video'></iframe></div>
        <img src={camera} alt="camera-vector" className='camera'></img>
        <h2 className="PhilHeader">MY PHILOSOPHY:</h2>
        <h2 className="ChaseHeader">CHASE ADVENTURE, CAPTURE THE STORY</h2>
        <div className="PhilDesc">
          <p className='PhilBody'>There’s a lot of moments to miss out there. My goal when it comes to photos/video is to
            capture the moment as it feels, not just as it looks. Seeking out opportunities to capture
            unique moments is what I gravitate towards.</p>
            <p>Visit the <Link to="/photography" target="_top">Photography Page</Link> to see more!</p>
        </div>
        <BottomContent className='bottomContent'/>
      </div>
    </div>
  );
}

export default Home;