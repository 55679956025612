import '../styles/standard.css';


function PrivacyPolicy() {
    return(
<div className='Privacy'>
<div className='content1'>
<div className='PrivacyText'>
<h1>PRIVACY POLICY</h1>
<h2>Mason Ayres Creative, LLC.</h2>
<p>Last updated: 7/9/2024</p>
<p>At Mason Ayres Creative, LLC, we are committed to
protecting your privacy. This Privacy Policy outlines 
how we collect, use, and safeguard your information 
when you visit our website, masonayres.design.</p>
<h3>1. Information We Collect</h3>
<p><strong>Personal Information: </strong> 
When you visit our website, we may collect personally identifiable information that you voluntarily provide to us. 
This may include your name, email address, phone number, and any other information you choose to provide.</p>
<p><strong>Usage Data: </strong>
We may also collect information about how you access and use our website. 
This data may include your IP address, browser type, browser version, 
the pages of our website that you visit, the time and date of your visit, 
the time spent on those pages, and other diagnostic data.</p>
<p><strong>Cookies and Tracking Technologies: </strong>
We use cookies and similar tracking technologies to track the activity on our website and hold certain information. 
Cookies are files with a small amount of data that are stored on your device.
 You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. 
However, if you do not accept cookies, you may not be able to use some portions of our website.</p>
<h3>2. How We Use Your Information</h3>
<p>Mason Ayres Creative, LLC uses the collected data for various purposes:
<ul>
    <li>To provide and maintain our website</li>
    <li>To notify you about changes to our website</li>
    <li>To allow you to participate in interactive features of our website when you choose to do so</li>
    <li>To provide customer support</li>
    <li>To gather analysis or valuable information so that we can improve our website</li>
    <li>To monitor the usage of our website</li>
    <li>To detect, prevent, and address technical issues</li>
</ul>
</p>
<h3>3. Google Analytics</h3>
<p>We use Google Analytics to collect and analyze information about how our website is used. 
Google Analytics uses cookies to collect information such as your IP address, browser type, and the pages you visit. 
This information is used to improve our website and services. 
For more information on Google Analytics' privacy practices, 
please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en-US">Google Privacy & Terms.</a></p>
<h3>4. Google reCAPTCHA</h3>
<p>We use Google reCAPTCHA to protect our website from spam and abuse. 
reCAPTCHA collects personal information from users to determine whether they are human and not a bot. 
The information collected by reCAPTCHA is subject to the Google Privacy Policy and Terms of Service. form more information, please visit the <a href="https://policies.google.com/privacy?hl=en-US">Google Privacy & Terms Webpage.</a> </p>
<h3>5. Sharing Your Information</h3>
<p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.</p>
<h3>6. Security of Your Information</h3>
<p>The security of your personal information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
<h3>7. Links to Other Websites</h3>
<p>Our website may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site.</p>

</div>
</div>
<div className='footer'>
          <a className="footerlinks" href="./privacypolicy">PRIVACY POLICY</a> 
          <a className="footerlinks" href="./contact">CONTACT</a> 
          <a className="footerlinks" href="https://www.masonayres.design/" target='_blank' rel='noreferrer'>LEGACY SITE</a>
          <a className="footerlinks" href="https://www.masonayres.design/" target='_blank' rel='noreferrer'>SITE MAP</a>  
        </div>   
</div>  )




}
export default PrivacyPolicy;