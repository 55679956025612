import '../styles/videography.css';
import LeftArrow from '../resources/Arrow Left Border.png';
import RightArrow from '../resources/Arrow Right Border.png';
import BottomContent from '../components/bottomContent';
import { useState } from 'react';

function Videography() {
    const [curVideo, setCurVideo] = useState(0);
    const [fadeOut, setFadeOut] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);

    const videoUrls = [
        'https://www.youtube.com/embed/RQUw1GI__0E',
        'https://www.youtube.com/embed/vFmmAT0zwWI',
        'https://www.youtube.com/embed/N41nhhQo7F0',
        'https://www.youtube.com/embed/Q0B_QAqR-KA',
        'https://www.youtube.com/embed/lyMkERA1fn8'
    ];
    const videoTitles = [
        'STARTING MASON AYRES CREATIVE',
        "WOMEN'S POWER EVENT",
        'MAST ROOFING: PROJECT PEACE OF MIND',
        'MAST ROOFING - HONEST REVIEWS',
        'RESTORATION TECHNICAL INSTITUTE - DIGITAL TRAINING SOLUTION'
    ];
    const videoDescriptions = [
        'A quick vlog about kicking off my business, and talking about life. First long form video on the Mason Ayres Creative Youtube channel.',
        "Just a small compilation shooting b-roll for <a href='https://picturesbytodd.com/' target='_blank'>Pictures By Todd</a> at the Women's POWER event at Lincoln Financial Field - at the Philadelphia Eagles' First Trust Bank Club Suite.",
        "For the past 13 years, <a href='https://mastsroofing.com/' target='_blank'>Mast Roofing & Construction</a> has given away a free roof to a person in need within the local community. During employment as their Brand Ambassador I captured the whole event, as well as participated in the installation!",
        "Getting the scoop on the clients of <a href='https://mastsroofing.com/' target='_blank'>Mast Roofing & Construction</a> to see whether or not their standards have been met for a reputable roofing company. Conducted a simple 5 minute long interview to hear their thoughts!",
        "Sharing a new technological advancement for the restoration industry, in 2023, <a href='https://rtilearning.com/' target='_blank'>Restoration Technical Institute</a> unveiled their new 'Digital Training Solution.'",
    ];

    const handleArrowClick = (direction) => {
        setFadeOut(true);
        setTimeout(() => {
            setCurVideo(prevIndex => {
                if (direction === 'left') {
                    return prevIndex === 0 ? videoUrls.length - 1 : prevIndex - 1;
                } else {
                    return prevIndex === videoUrls.length - 1 ? 0 : prevIndex + 1;
                }
            });
            setFadeOut(false);
            setFadeIn(true);
            setTimeout(() => {
                setFadeIn(false);
            }, 500); // Duration of the fade-in
        }, 500); // Duration of the fade-out
    };

    return (
        <div className="videography">
             <h2 className={`videotitle ${fadeOut ? 'fade-out' : ''} ${fadeIn ? 'fade-in' : ''}`}>{videoTitles[curVideo]}</h2>
            <div className='videoSection'>
                <div className='arrow leftArrow' onClick={() => handleArrowClick('left')}><img src={LeftArrow} alt='left arrow' /></div>
                <iframe className='VideoMain' src={videoUrls[curVideo]} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <div className='arrow rightArrow' onClick={() => handleArrowClick('right')}><img src={RightArrow} alt='right arrow' /></div>
            </div>
           
            <p className={`videodescription ${fadeOut ? 'fade-out' : ''} ${fadeIn ? 'fade-in' : ''}`} dangerouslySetInnerHTML={{ __html: videoDescriptions[curVideo] }}></p>
            <BottomContent />
        </div>
    );
}

export default Videography;