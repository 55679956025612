import '../styles/navBar.css';
import headerIcon from '../resources/HeaderIcon.svg';
import { Link } from 'react-router-dom';
import HamburgerButton from '../components/hamburgerButton';
import { useState, useEffect } from 'react';

function NavBar() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1280);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, []);

    return (
            <nav className='nav'>
              <div className='headerIcon'>
                <Link to='/' className='homeImg' target='_top'><img src={ headerIcon } alt='Header Icon'></img></Link> 
              </div>
              {!isMobile && (
                <>
                  <Link to='/graphic-design' target='_top'>GRAPHIC DESIGN</Link>
                  <Link to='/photography' target='_top'>PHOTOGRAPHY</Link>
                  <Link to='/videography' target='_top'>VIDEOGRAPHY</Link>
                  <Link to='/about' target='_top'>ABOUT</Link>
                  <Link to='/contact' target='_top'>CONTACT</Link>
                </>
              )}
              {isMobile && (
                <div className='hamburgerMenu' onClick={ toggleHamburger }>
                  <HamburgerButton />
                  {hamburgerOpen && (
                    <div className='verticalMenu'>
                      <Link to='/'>HOME</Link>
                      <Link to='/graphic-design'>GRAPHIC DESIGN</Link>
                      <Link to='/photography'>PHOTOGRAPHY</Link>
                      <Link to='/videography'>VIDEOGRAPHY</Link>
                      <Link to='/about'>ABOUT</Link>
                      <Link to='/contact'>CONTACT</Link>
                    </div>
                  )}
              </div>
              )}
            </nav>
      );
}

export default NavBar;