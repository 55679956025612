import '../styles/about.css';
import BottomContent from '../components/bottomContent';
import mason from '../resources/MasonHeadshotCropped.jpg';
import Carousel from '../components/carousel';
/*import Reviews from '../components/reviews'; */
function About() {
    return (
        <div className="about">
            <div className="AboutTitle"><h1>ABOUT MASON AYRES CREATIVE</h1></div>

            <div className='belowContent'>
                <div className='im25'>
                    <p><strong>I'm a solo creative based in Leesport, Pennsylvania,</strong> helping people achieve some awesome brand design, videograpy & photography.</p>
                </div>
                <div className="MoreInfo">
                <section className="bodyCopy">
                <p>My name is <strong>Mason Ayres,</strong> and I've been a dedicated, multi-disciplinary freelance creative for over 7 years.</p>
                <p>I officially started this company in 2024 to help people do more to achieve their creative visions, whatever they may be. There are a lot of people out there that look for creative help in their endeavors, and I've made it my mission to be a resource for those people. </p>
                <p>Photography and Videography are personal passions of mine that I've brought into my business model to give even more value to clients, becoming sort of a one-stop-shop for creative services.</p>
               
                </section>
                <img src={ mason } alt='Mason Headshot'></img>
                </div>
                <div className="Pricing">
                 <h2>PRICING</h2> 
                 <p>Coming soon, check back for documentation!</p>  
                </div>
               {/*<Reviews />*/} 

                <div className='resume help'>
                    <p>PEOPLE I'VE HELPED</p>
                </div>
                
                <Carousel></Carousel>
                <BottomContent />
            </div>
        </div>
    )
  };
  
  export default About;
  