import React from 'react';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const apiBaseUrl = process.env.REACT_APP_NODE_ENV === 'production' 
        ? 'https://www.masonayres.design/api'
        : 'http://localhost:8080/api';

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
        console.log('Execute reCAPTCHA not yet available');
        return null;
        }

        try {
        const token = await executeRecaptcha('contactForm');
        console.log('Verified token:', token);
        return token;
        } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
        return null;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const token = await handleReCaptchaVerify();
        if (!token) {
        alert('reCAPTCHA verification failed. Please try again.');
        return;
        }

        const formData = new FormData(event.target);
        const data = {
        name: formData.get('name'),
        email: formData.get('email'),
        subject: formData.get('subject'),
        message: formData.get('message'),
        recaptchaToken: token,
        };

        try {
            console.log(`Sending request to: ${apiBaseUrl}/send-email`)
            await axios.post(`${apiBaseUrl}/send-email`, data);
            alert('Your message has been sent!');
            event.target.reset();
        } catch (error) {
        console.error('Error sending message:', error);
        alert('Failed to send message. Please try again later.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="formRow">
                <div className="formLeft">
                    <label htmlFor="name">NAME</label>
                    <input type="text" id="name" name="name" required />
                    <label htmlFor="email">EMAIL</label>
                    <input type="email" id="email" name="email" required />
                    <label htmlFor="subject">SUBJECT</label>
                    <input type="text" id="subject" name="subject" />
                </div>
                <div className="formRight">
                    <label htmlFor="message">YOUR MESSAGE</label>
                    <textarea id="message" name="message" required></textarea>
                </div>
            </div>
            <button type="submit">SUBMIT</button>
        </form>
    );
};

export default ContactForm;