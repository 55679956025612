import '../styles/photography.css';
import BottomContent from '../components/bottomContent';
import React, { useState, useEffect } from 'react';

const contexts = {
    headshots: require.context('../resources/images/headshots', true),
    corporate: require.context('../resources/images/corporate', true),
    portraits: require.context('../resources/images/portraits', true),
    products: require.context('../resources/images/products', true),
    events: require.context('../resources/images/events', true)
};

function getUniqueRandomNumbers(count, numImages) {
    if (numImages > count) {
        throw new Error("Requested more unique random numbers than available images");
    }

    const uniqueNumbers = new Set();
    while (uniqueNumbers.size < numImages) {
        const randomNumber = Math.floor(Math.random() * count);
        uniqueNumbers.add(randomNumber);
    }
    return Array.from(uniqueNumbers);
}

function Photography() {
    const [curDescriptor, setCurDescriptor] = useState('corporate');
    const [refreshKey, setRefreshKey] = useState(0); // State to force image refresh
    const numImages = 24; // Number of images to display

    const images = contexts[curDescriptor];
    const imageList = images.keys().map(image => images(image));

    let uniqueNumbers = [];
    try {
        uniqueNumbers = getUniqueRandomNumbers(imageList.length, numImages);
    } catch (error) {
        console.error(error.message);
    }

    useEffect(() => {
        setRefreshKey(prevKey => prevKey + 1);
    }, [curDescriptor]);

    const handleDescriptorClick = (descriptor) => {
        if (curDescriptor === descriptor) {
            setRefreshKey(prevKey => prevKey + 1); // Force refresh if the same category is clicked again
        } else {
            setCurDescriptor(descriptor); // Change category
        }
    };

    return (
        <div className="photography">
            <div className='descriptorsOuter'>
                <h1>PHOTOGRAPHY BY MASON AYRES</h1>
                <p>Click a category to see a random gallery of images. Photos will refresh when clicking another category or the same one again.</p>
                <div className='descriptorsInner'>
                    <a
                        className={curDescriptor === 'headshots' ? 'active' : ''}
                        onClick={() => handleDescriptorClick('headshots')}
                        href="#headshots"
                    >
                        HEADSHOTS
                    </a>
                    <a
                        className={curDescriptor === 'corporate' ? 'active' : ''}
                        onClick={() => handleDescriptorClick('corporate')}
                        href="#corporate"
                    >
                        CORPORATE/BRANDING
                    </a>
                    <a
                        className={curDescriptor === 'portraits' ? 'active' : ''}
                        onClick={() => handleDescriptorClick('portraits')}
                        href="#portraits"
                    >
                        PORTRAITS
                    </a>
                    <a
                        className={curDescriptor === 'products' ? 'active' : ''}
                        onClick={() => handleDescriptorClick('products')}
                        href="#products"
                    >
                        PRODUCTS
                    </a>
                    <a
                        className={curDescriptor === 'events' ? 'active' : ''}
                        onClick={() => handleDescriptorClick('events')}
                        href="#events"
                    >
                        EVENTS
                    </a>
                </div>
            </div>
            {uniqueNumbers.length > 0 ? (
                <div className='imageContainer'>
                    <div className='leftImages'>
                        {uniqueNumbers.slice(0, 8).map((num, index) => (
                            <img key={`${refreshKey}-${index}`} src={imageList[num]} alt={`${curDescriptor}${index + 1}`} />
                        ))}
                    </div>
                    <div className='middleImages'>
                        {uniqueNumbers.slice(8, 15).map((num, index) => (
                            <img key={`${refreshKey}-${index + 8}`} src={imageList[num]} alt={`${curDescriptor}${index + 9}`} />
                        ))}
                    </div>
                    <div className='rightImages'>
                        {uniqueNumbers.slice(15, 24).map((num, index) => (
                            <img key={`${refreshKey}-${index + 15}`} src={imageList[num]} alt={`${curDescriptor}${index + 16}`} />
                        ))}
                    </div>
                </div>
            ) : (
                <div className="photography">
                    <div className='descriptorsOuter'>
                        <h1>PHOTOGRAPHY BY MASON AYRES</h1>
                        <p>Not enough images available in this category.</p>
                    </div>
                </div>
            )}
            <div className='DividerLine'></div>
            <BottomContent />
        </div>
    );
}

export default Photography;